/* eslint-disable */
// THIS FILE IS AUTO-GENERATED!
import React from 'react';
import { Switch, LoadableRoute } from '@e4/ebanking-web-utils';

const routes = (
  <Switch>
    <LoadableRoute
      path="(/nb)?/logoff"
      key="(/nb)?/logoff"
      
      loader={() => import('@e4/ebanking-web-logoff')}
    />
<LoadableRoute
      path="(/nb)?/menu"
      key="(/nb)?/menu"
      
      loader={() => import('@e4/ebanking-web-menu')}
    />
<LoadableRoute
      path="(/nb)?/odd"
      key="(/nb)?/odd"
      
      loader={() => import('@e4/ebanking-web-odd')}
    />
<LoadableRoute
      path="(/nb)?/fia"
      key="(/nb)?/fia"
      
      loader={() => import('@e4/ebanking-web-fia')}
    />
  </Switch>
);

export default routes;
